import { useEffect, useState } from "react";
import { API_getStaffCompany, API_staffPitfallAddCompany, API_staffPitfallEditCompany } from "../../service/common";
import { Button, Cell, Form, Infiniteloading, Input, Popup, Toast } from "@nutui/nutui-react";
import Header from "../../components/header";
import {  useNavigate } from "react-router-dom";
var chooseIndex=-1;
const MyCompany = (props) => {
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(true);
  const [list,setList]=useState([])
  const userType=localStorage.getItem('userType')
  const [pageIndex, setPageIndex] = useState(1);
  const [form] = Form.useForm();
  const loadMore = () => {
    if(!hasMore){return}
    let reqData={
      PageIndex:pageIndex,
      PageSize:20
    }
    if(userType==4){
      reqData.Organid=localStorage.getItem('organId')
    }else{
      reqData.StaffId=localStorage.getItem('staffId')
    }
    API_getStaffCompany(reqData).then(res=>{
      if(pageIndex==1){
        setList(res.data)
      }else{
        setList(list.concat(res.data));
      }
      if(res.data.length<20){
        setHasMore(false);
      }
    })
  }
  useEffect((e) => {
    window.scrollTo(0, 0);
  },[]);
  useEffect((e) => {
    if(!hasMore) return;
    loadMore();
  },[pageIndex]);

  const goPitFallList=(item)=>{
    localStorage.setItem('companyPoint',item.point.join(','))
    localStorage.setItem('companyName',item.information)
    localStorage.setItem('companyInfo',JSON.stringify(item))
    navigate('/pitfall/pitfallList/'+item.id)
  }

  const [showBottom, setShowBottom] = useState(false);


  const submitSucceed = () => {
    let reqData={
      information:form.getFieldValue("information"),
      address:form.getFieldValue("address"),
      contact:form.getFieldValue("contact"),
      point:[120,30],
      photos: [],
      ServerItem:[],
      organStaffIds:[localStorage.getItem('staffId')]
    }

    if(chooseIndex!=-1){
      reqData.id=list[chooseIndex].id;
      API_staffPitfallEditCompany(reqData).then((res) => {
        Toast.success('修改成功')
        list[chooseIndex]=reqData;
        setList([...list])
        setShowBottom(false)
        form.resetFields();
      });
    }else{
      API_staffPitfallAddCompany(reqData).then((res) => {
        Toast.success('添加成功')
        setShowBottom(false)
        form.resetFields();
        setHasMore(true);
        pageIndex=1;
        setList([]);
        loadMore(true);
      });
    }


  };



  return (
    <div>
      <Header title="负责公司列表" ></Header>
      <div id='scroll'>
        <Infiniteloading
          hasMore={hasMore}
          onLoadMore={e=>setPageIndex(e=>e+1)}
        >
          {list.map((item, index) => {
            return (
              <Cell onClick={e=>{goPitFallList(item)}} key={item.id} title={item.information} subTitle={item.address} isLink >

              </Cell>
            )
          })}
        </Infiniteloading>
      </div>
      <div className="footer-btn-fix">
        <div className="warp"></div>
        <div className="btns">
        <Button
            className="button"
            style={{ width: "100%" }}
            type="info"
            block
            onClick={(e) => {
              setShowBottom(true);
            }}
          >
            新增公司
          </Button>
        </div>
      </div>
      <Popup
       destroyOnClose
        visible={showBottom}
        style={{}}
        position="bottom"
        onClose={() => {
          setShowBottom(false);
        }}
      >
        <Form form={form}>
          <Form.Item
            label="企业名称"
            name="information"
            rules={[{ required: true, message: "请输入企业名称" }]}
          >
            <Input placeholder="请输入企业名称" />
          </Form.Item>
          <Form.Item
            label="企业地址"
            name="address"
            rules={[{ required: true, message: "请输入企业地址" }]}
          >
            <Input placeholder="请输入企业地址" />
          </Form.Item>
          <Form.Item
            label="联系电话"
            name="contact"
            rules={[{ required: true, message: "请输入联系电话" }]}
          >
               <Input type='number' placeholder="请输入联系电话" />
          </Form.Item>
          <div className="footer-btn">
            <Button type="info" block onClick={submitSucceed}>
              保存
            </Button>
          </div>
        </Form>
      </Popup> 
    </div>
  );
};

export default MyCompany;
